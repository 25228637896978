/* eslint-disable */
<template>
  <fragment>
    <Design :productId="productId"/>
  </fragment>
</template>

<script type="text/javascript">
/* eslint-disable */
import Design from './Design';
export default {
  name: "App",
  components: {
    Design
  },
  data() {
    return {
      productId: ''
    };
  },
  methods: {
    createUser(userForm) {
      console.log("creating", userForm);

      // call an API to create a new user
    },
    updateUser(userForm) {
      console.log("updating", userForm);

      // call an API to update the existing user
    },
  },
};
</script>